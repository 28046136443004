<template>
  <v-container>
    <v-row align-content="center">
      <v-col cols="12" sm="7" md="7" lg="7" align-self="end" class="pb-1">
        <!-- <v-btn color="primary" @click="openDialog"
          >Crear reserva</v-btn> -->
        <v-autocomplete
        :items="reservations"
        :filter="customFilter"
        color="white"
        item-text="name"
        label="Nombre"
      ></v-autocomplete>

      </v-col>
    </v-row>
    <v-row class="dense" v-if="reservations.length > 0">
      <v-col cols="12">
        <v-card-text >
          <v-row>
            <v-col
              v-for="(res, index) in reservations"
              :key="index"
              cols="12"
            >
              <v-card>
                <v-list-item two-line>
                  <v-list-item-content>
                    <div class="overline mb-4">
                      {{ res.start_time }} - {{ res.end_time }}
                    </div>
                    <v-list-item-title class="headline mb-1">
                      {{ res.name }}
                      {{ res.last_name }}</v-list-item-title
                    >
                    <v-list-item-subtitle>{{ res.email }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-content>
                    <div class="overline mb-4">
                      N° comensales: {{ res.diners }}
                    </div>
                    <div class="overline mb-4">
                      Fecha: {{ res.date }}
                    </div>
                    <div class="overline mb-4">
                      Teléfono: {{ res.phone }}
                    </div>

                  </v-list-item-content>
                </v-list-item>

                <v-card-actions>
                  <v-btn text> <v-icon>mdi-pencil</v-icon> </v-btn>
                  <v-btn text @click="confirmDialog(res.id)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>
    <!-- Confirmar borrados -->
    <template>
      <div class="text-center">
        <v-dialog
          v-model="dialogConfirm"
          width="500"
        >
          <v-card>
            <v-card-title class="headline grey lighten-2">
              ¿Confirma que desea borrar?
            </v-card-title>

            <v-card-text>
               ¿Seguro que desea borrar la reserva?
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="deleteItem"
              >
                Si
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <crearReservation
      @closeModalReserva="updateModalReservation"
      @updateAll="init"
      :openReservation="openReservation"
      :restaurant_id="restaurant_id"
      :eventForReserva="eventForReserva"
      :url="url"
    />
  </v-container>
</template>
<script>
import crearReservation from "../../../components/CrearReservation";
export default {
  components: { crearReservation },
  name:'reserva',
  data() {
    return {
      reservations:[],
      restaurant_id:null,
      url_edit:'',
      redirect:'',
      openReservation: false,
      eventForReserva:{},
      url:'',
      hasSaved: false,
        isEditing: null,
        model: null,
      dialogConfirm:false,
      idDelete:null
    }
  },
  created() {
    if (this.$store.getters["auth/isAdmin"]) {
      this.restaurant_id = this.$route.params ? this.$route.params.id : "";
      this.url_edit = `/tc-admin/horarios/${this.restaurant_id}/administrar/horarios/`;
      this.redirect = `/tc-admin/horarios/${this.dishe.restaurant_id}/administrar/platos`;
    } else {
      this.restaurant_id = this.$store.getters["auth/restaurant"];
      this.url_edit = `/tc-admin/horarios/`;
      this.redirect = "/tc-admin/horarios";
    }
    this.init();

    this.$store.commit("SET_OVERLAY", false);

  },
  methods: {
    init(){
      this.getReservations();
    },
    async getReservations() {
      let url = "api/booking/" + this.restaurant_id;
      this.$api
        .get(url)
        .then(res => {
          this.reservations = res.data.reservation;
        })
        .catch(error => {
          console.log(error);
        });
    },
    updateModalReservation: function(val) {
      this.openReservation = val;
    },
    openDialog(){

      this.openReservation = true
    },
    customFilter (item, queryText, itemText) {
        const textOne = item.name.toLowerCase()
        const textTwo = item.last_name.toLowerCase()
        const searchText = queryText.toLowerCase()

        return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1
      },
    confirmDialog(id,type){
      this.idDelete = id;
      this.dialogConfirm = true;
    },
    deleteItem(){
      let url = "api/booking/" +  this.idDelete;
      this.$api.delete(url).then(res=>{
          this.getReservations();
      });
      this.dialogConfirm = false;
    },
  },
};
</script>
